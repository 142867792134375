table {
  .p-column-title {
    @extend .ms-3;
  }

  tr {
    color: nb-theme(text-basic-color) !important;
    border-width: 0 !important;
  }

  td,
  th {
    position: relative;
    vertical-align: middle;
    box-shadow: none !important;
  }

  &.table-bordered {
    td,
    th {
      border: 1px solid nb-theme(border-basic-color-3) !important;
    }
  }

  @include media-breakpoint-up(lg) {
    &.table-striped {
      tbody {
        tr {
          &:nth-child(2n) {
            background: nb-theme(background-basic-color-2);
          }

          &:hover,
          &:active {
            background: nb-theme(background-basic-color-3);
          }
        }
      }
    }
    &.table-bordered {
      tr{
        &:last-child td {
          border-bottom-width: 1px !important;
        }
      }
    }
    tr {
      th {
        border-bottom: 1px solid nb-theme(text-basic-color) !important;
      }

      td {
        border-bottom: 1px solid nb-theme(border-basic-color-3) !important;
      }

      &:last-child td {
        border-bottom-width: 0 !important;
      }
    }
  }
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: unset !important;
  @include media-breakpoint-up(lg) {
    overflow-x: auto !important;
  }
}

.stacked-table {
  thead {
    tr:first-child {
      display: none;
      @include media-breakpoint-up(lg) {
        display: table-row;
      }
    }
  }

  &.table-bordered {
    td,
    th {
      border: none !important;
      @include media-breakpoint-up(lg) {
        border: 1px solid nb-theme(border-basic-color-3) !important;
      }
    }
  }

  tr {
    box-shadow: 0 1px 1rem nb-theme(background-basic-color-3);
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: block;
    margin: 0 -0.75rem 1rem -0.75rem;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    td {
      flex-wrap: wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & td:last-child {
      border-bottom-width: 0;
      @include media-breakpoint-up(lg) {
        border-bottom-width: 1px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: unset;
      box-shadow: none;
      display: table-row;
      margin: 0;
      &:first-child {
        display: table-row;
      }
    }
  }
}

.p-datatable-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(nb-theme(background-basic-color-2), 0.5);
  backdrop-filter: blur(5px);
  border-radius: 0.25rem;

  i {
    animation: spin 400ms infinite linear;
    font-size: 2rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-weight: 400;
      content: '\f3f4';
      font-style: normal;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.p-paginator {
  .p-paginator-first {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .p-paginator-last {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left-width: 1px;
  }

  button {
    background: transparent;
    box-shadow: none;
    width: 30px;
    appearance: none;
    height: 30px;
    font-size: 0.875rem;
    border: 1px solid nb-theme(border-basic-color-3);
    color: nb-theme(text-basic-color);
    border-left-width: 0;
    @include media-breakpoint-up(lg) {
      width: 40px;
      height: 40px;
      font-size: 1rem;
    }

    &.p-disabled {
      color: nb-theme(color-basic-disabled);
      cursor: default;

      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background: nb-theme(color-primary-transparent-hover);
    }

    &.p-highlight {
      color: white;
      background: nb-theme(color-primary-default);
    }
  }

  .p-paginator-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pi-angle-left:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    content: '\f054';
  }

  .pi-angle-right:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    content: '\f053';
  }

  .pi-angle-double-left:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    content: '\f053';
  }

  .pi-angle-double-right:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    content: '\f323';
  }

  .pi-angle-double-left:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    content: '\f324';
  }
}
