@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$bg-color-1: #131315;
$bg-color-2: #1C1D20;
$bg-color-3: #232325;
$bg-color-4: #28282a;
$bg-color-5: #161618;
$text-color: #fff;
$text-color-2: #8D8D8D;
$text-color-3: #A7A7A8;
$card-bg-color: #1c1d2091;
$card-gradient-bg-color: #785A97FF;
$primary: #845ea5;

$font-family-sans-serif: "Yekan Bakh", 'Gilroy', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$text-danger: #FF5F5F;
$text-success: #7CEB91;
$text-warning: #E6CD64;
$text-disabled: #777779;
$border-color: #785A97;
$text-warning: #E6CD64;
$text-black: #000;
$text-gray: #F7F7F700;
$text-black-2: #070707;
$text-black-2: #070707;
$text-gray-2: #404040;
$bg-table: #1B1B1D;
$bg-table2: #2B2B2E;
$text-success-secondary: #55B467;
