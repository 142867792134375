@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Black.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb_Black.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb_Black.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb_Black.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Bold.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb_Bold.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb_Bold.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb_Bold.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Medium.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb_Medium.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb_Medium.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb_Medium.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/iran_sans/eot/IRANSansWeb.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Light.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb_Light.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb_Light.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb_Light.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/iran_sans/eot/IRANSansWeb_UltraLight.eot');
  src: url('../fonts/iran_sans/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),
    url('../fonts/iran_sans/woff/IRANSansWeb_UltraLight.woff') format('woff'),
    url('../fonts/iran_sans/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Black.eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Black.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum)_Black.woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum)_Black.woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum)_Black.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Bold.eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Medium.eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum).eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum).woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Light.eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

@font-face {
  font-family: IRANSans_FaNum;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_UltraLight.eot');
  src: url('../fonts/iran_sans/faNum/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/iran_sans/faNum/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),
    url('../fonts/iran_sans/faNum/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),
    url('../fonts/iran_sans/faNum/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
  unicode-range: U+0600 -U+06FF;
}

.faNum {
  font-family: 'IRANSans_FaNum', 'IRANSans', Tahoma, Arial, 'Roboto', sans-serif !important;
}
