ngx-skeleton-loader span {
  margin: 0 !important;

  &::before {
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(164, 157, 157, 0.2),
      transparent
    ) !important;
  }
}

ngx-skeleton-loader {
  &.rounded-icon-35 .loader {
    height: 35px;
    background-color: #13132b;
    width: 35px;
    vertical-align: middle;
    border-radius: 50%;
  }

  &.text-skeleton .loader {
    background-color: nb-theme(background-basic-color-4);
    height: 15px;
    width: 110px;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.tv_chart_container iframe {
  height: 100% !important;
}

ngx-skeleton-loader {
  .loader {
    background: nb-theme(background-basic-color-4) !important;
  }
}
