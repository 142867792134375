@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~bootstrap/scss/bootstrap';
@import 'assets/scss/font.css';
@import 'assets/fonts/fontawesome/css/all.min.css';
@import '~viewerjs/dist/viewer.css';
@import "assets/scss/variables";
@import "assets/scss/select";

@include nb-install() {
  @include nb-theme-global();
  @import 'assets/scss/table';
  @import 'assets/scss/skeleton';

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  router-outlet + * {
    display: block; /* Change display inline to block */
    animation: fade 1s;
  }

  /* width */
  ::-webkit-scrollbar {
    width: nb-theme(scrollbar-width);
    height: nb-theme(scrollbar-width);
  }

  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track:horizontal {
    background: nb-theme(scrollbar-background-color);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:horizontal {
    background: nb-theme(scrollbar-color);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background: nb-theme(scrollbar-color);
  }
  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @-moz-document url-prefix() {
      -webkit-appearance: textfield !important;
    }
  }
  input[type='email'] {
    direction: ltr;
  }
  .placeholder-right::placeholder {
    text-align: right !important;
  }
  .text-primary {
    color: nb-theme(color-primary-400) !important;
  }
  a {
    text-decoration: none;
  }
  nb-user {
    .info-container {
      .user-name {
        margin-bottom: 0.25rem;
      }

      @include nb-ltr() {
        margin-right: 0.5rem !important;
      }
      @include nb-rtl() {
        margin-left: 0.5rem !important;
      }
    }
  }
  nb-menu .menu-item .menu-icon {
    @include nb-rtl() {
      margin: 0 0 0 1rem;
    }
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: nb-theme(text-basic-color);
    -webkit-background-clip: text;
    caret-color: nb-theme(text-basic-color);
    color: nb-theme(text-basic-color);
    border: 1px solid nb-theme(border-basic-color-4);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px nb-theme(background-basic-color-2) inset;
    background-color: nb-theme(background-basic-color-2);
  }
  nb-context-menu {
    min-width: 16rem;
  }
  nb-stepper .header .step.completed .label-index {
    background-color: nb-theme(color-success-400);
    border-color: nb-theme(color-success-400);
  }
  nb-stepper .header .step.selected .label-index {
    border-color: nb-theme(color-success-400);
  }
  nb-stepper .header .step.selected {
    color: nb-theme(color-success-600);
  }
  nb-stepper .header .connector-past {
    background-color: nb-theme(color-success-400);
  }
  .swal2-popup.swal2-toast {
    background: nb-theme(background-basic-color-1) !important;
    box-shadow: 0 0 0.625em nb-theme(background-basic-color-1) !important;
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-confirm,
  .swal2-deny,
  .swal2-cancel {
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-content {
    color: nb-theme(text-basic-color);
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-actions {
    flex-direction: row-reverse;
  }
  .swal2-popup {
    background: nb-theme(background-basic-color-1) !important;
  }
  nb-select {
    button {
      min-width: unset !important;
    }
  }
  .text-success {
    color: nb-theme(text-success-color) !important;
  }
  .text-danger {
    color: nb-theme(text-danger-color) !important;
  }
  .text-warning {
    color: nb-theme(text-warning-color) !important;
  }
  .text-primary {
    color: nb-theme(text-primary-color) !important;
  }
  .pointer-none {
    pointer-events: none !important;
  }
  .no-wrap {
    white-space: nowrap !important;
  }
  .smart-table-responsive {
    max-width: unset;
  }
  .img-modal {
    display: block;
    max-width: 100% !important;
    max-height: 497px;
  }
  .swal2-popup.swal2-toast {
    background: nb-theme(background-basic-color-1) !important;
    box-shadow: 0 0 0.625em #414c6d !important;
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-confirm,
  .swal2-deny,
  .swal2-cancel {
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-content {
    color: nb-theme(text-basic-color);
    font-family: 'IRANSans_FaNum', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .swal2-actions {
    flex-direction: row-reverse;
  }
  .swal2-popup {
    background: nb-theme(background-basic-color-1) !important;
  }
  ng2-st-tbody-edit-delete {
    display: flex !important;
    height: 0 !important;
  }

  ng2-st-tbody-custom a.ng2-smart-action.ng2-smart-action-custom-custom {
    display: inline-block;
    width: 50px;
    text-align: center;
    font-size: 1.1em !important;
  }

  ng2-st-tbody-custom a.ng2-smart-action.ng2-smart-action-custom-custom:hover {
    color: nb-theme(text-primary-color);
  }

  ng2-smart-table .ng2-smart-actions {
    height: auto !important;
  }

  ng2-smart-table .ng2-smart-actions a.ng2-smart-action:not(:last-child) {
    border: none !important;
  }
  .loading-backdrop {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    background: #0000004d;
    backdrop-filter: blur(5px);
    overflow: hidden;
  }
  .loading-image {
    width: 700px;
    height: 700px;
  }
  .table {
    color: nb-theme(text-basic-color);
    border-color: nb-theme(border-basic-color-2);
    --bs-table-hover-color: nb-theme(text-basic-color);
  }
  .table-fixed tbody {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
  }

  .table-fixed thead,
  .table-fixed tbody,
  .table-fixed tr,
  .table-fixed td,
  .table-fixed th {
    display: block;
  }

  .table-fixed tbody td,
  .table-fixed tbody th,
  .table-fixed thead > tr > th {
    position: relative;
    float: right;

    &::after {
      content: '';
      clear: both;
      display: block;
    }
  }
  tr {
    vertical-align: middle;
  }
  .swal2-popup.swal2-toast .swal2-header {
    padding-left: 0.5rem;
  }
  select-filter select,
  input-filter input {
    background-color: nb-theme(background-basic-color-2);
    border-color: nb-theme(border-basic-color-4);
    color: nb-theme(text-basic-color);

    &:hover,
    &:focus {
      outline: none;
      background-color: nb-theme(background-basic-color-1);
      border-color: nb-theme(color-primary-transparent-hover-border);
      color: nb-theme(text-basic-color);
      box-shadow: none;
    }
  }
  .en-num {
    font-family: 'IRANSansWeb', 'IRANSans', Tahoma, Arial, 'Roboto', sans-serif;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .full-width {
    width: 100% !important;
  }
  .coin-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    section {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }

      .coin-name {
        display: flex;
        margin: 0;
      }
    }
  }
  .custom-card {
    background: nb-theme(background-basic-color-2);
  }
  .fs-small {
    font-size: 0.875rem;
  }
  ngx-skeleton-loader span {
    margin: 0 !important;

    &::before {
      background-image: linear-gradient(
          90deg,
          transparent,
          rgba(164, 157, 157, 0.2),
          transparent
      ) !important;
    }
  }
  ngx-skeleton-loader {
    .loader {
      background-color: nb-theme(background-basic-color-4);
    }

    &.rounded-icon-35 .loader {
      height: 35px;
      width: 35px;
      vertical-align: middle;
      border-radius: 50%;
    }

    &.text-skeleton .loader {
      height: 15px;
      width: 110px;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }
  .is-invalid {
    border: 1px solid nb-theme(color-danger-default);
  }
  .pagination-input {
    position: absolute;
    bottom: 17px;
    left: 0;
    width: 145px;

    .go-to-page-input {
      border-radius: 0 0.25rem 0.25rem 0;
      width: 100%;
    }

    .go-to-page-button {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
  .code {
    font-family: 'DM Mono', monospace;

    & nb-tooltip .content span {
      font-family: 'DM Mono', monospace;
      word-break: break-all;
      text-align: center;
    }
  }
  nb-tooltip .content span {
    word-break: break-word;
  }

  .auth-code {
    letter-spacing: 0.75rem;
  }
  .viewer-toolbar > ul > li {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  .dark-smart-table {
    input, input.form-control {
      color: #212529 !important;
    }
  }

  .dark-form {
    input {
      background: #1f2128;
      border: 1px solid #222f52;
      border-radius: 6px;
      padding: 10px;
      color: #ffff;
    }
  }

  @for $i from 12 to 26 {
    .fs-#{$i} {
      font-size: 0.075rem - (($i - 5) * -0.1) !important;
    }
  }
}


.tox-notification { display: none !important }