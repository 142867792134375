ng-select {
  width: 100% !important;
  display: flex !important;
  border-radius: 1rem;

  &.is-invalid {
    border: 2px solid $danger;
  }

  .ng-arrow-wrapper {
    padding: 0 !important;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg id='Iconly_Bulk_Arrow_-_Up_Square' data-name='Iconly/Bulk/Arrow - Up Square' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Arrow_-_Up_Square' data-name='Arrow - Up Square' transform='translate(22 22) rotate(180)'%3E%3Cpath id='Fill_1' data-name='Fill 1' d='M0,5.916v8.168C0,17.623,2.276,20,5.665,20h8.67C17.724,20,20,17.623,20,14.084V5.916C20,2.378,17.723,0,14.334,0H5.665C2.276,0,0,2.378,0,5.916' transform='translate(0 0)' fill='%23fff' opacity='0.4'/%3E%3Cpath id='Fill_4' data-name='Fill 4' d='M.218,5.687,3.966,9.452a.773.773,0,0,0,1.064,0L8.778,5.687A.751.751,0,0,0,7.714,4.628L5.247,7.105V.75a.75.75,0,0,0-1.5,0V7.105L1.281,4.628A.75.75,0,0,0,.218,5.687' transform='translate(5.502 5.168)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: cover;
    background-position: center;
    transform: rotate(180deg);
    transition: transform 300ms ease;

    .ng-arrow {
      display: none !important;
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg id='Iconly_Bulk_Arrow_-_Up_Square' data-name='Iconly/Bulk/Arrow - Up Square' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Arrow_-_Up_Square' data-name='Arrow - Up Square' transform='translate(22 22) rotate(180)'%3E%3Cpath id='Fill_1' data-name='Fill 1' d='M0,5.916v8.168C0,17.623,2.276,20,5.665,20h8.67C17.724,20,20,17.623,20,14.084V5.916C20,2.378,17.723,0,14.334,0H5.665C2.276,0,0,2.378,0,5.916' transform='translate(0 0)' fill='%23fff' opacity='0.4'/%3E%3Cpath id='Fill_4' data-name='Fill 4' d='M.218,5.687,3.966,9.452a.773.773,0,0,0,1.064,0L8.778,5.687A.751.751,0,0,0,7.714,4.628L5.247,7.105V.75a.75.75,0,0,0-1.5,0V7.105L1.281,4.628A.75.75,0,0,0,.218,5.687' transform='translate(5.502 5.168)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
      transform: rotate(0);
    }
  }
}

.ng-select .ng-select-container {
  color: $text-color;
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  text-align: center;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  input {
    direction: ltr;
    height: 100%;
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}


.ng-dropdown-panel {
  background-color: $bg-color-3;
  border: none;
  overflow: hidden;
  animation: growDown 300ms ease forwards;
  transform-origin: top center;
  border: 0 !important;

  .scroll-host {
    scrollbar-color: $bg-color-1 $bg-color-3;
    scrollbar-width: none;
  }

  &.ng-select-bottom {
    top: 113%;
    border: none;
    border-radius: 1rem;
  }

  .ng-dropdown-panel-items {
    border-radius: 1rem;
    padding: 0.5rem;


    .ng-option {
      background-color: $bg-color-3;
      color: #ffff;
      padding: 0.5rem 1rem;

      &.ng-option-marked {
        background-color: $bg-color-4;
        color: #ffff;
        border-radius: 1rem;
      }

      &.ng-option-selected {
        background-color: $bg-color-4;
        color: $text-color;
        border-radius: 1rem;

        &.ng-option-marked {
          background-color: $bg-color-4;
          color: $text-color;
          border-radius: 1rem;
        }
      }

      &:last-child {
        border-radius: 1rem !important;
      }
    }
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 57px;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}


.modal {
  .ng-select .ng-select-container {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px;
    background: #1f2128;
    border: 1px solid #222f52;
    border-radius: 6px;
  }
}
